import React from 'react'

const AtlantaNavbar = () => {
    return (
        <>
            <div className="menu-holder wide version2">
                <div className="menu-nav">
                    <ul>
                        <li>
                            <a href="/bootcamps/atlanta">Home</a>
                        </li>
                        <li>
                            <a href="/bootcamps/atlanta/schedule">Schedule</a>
                        </li>
                        <li>
                            <a href="/bootcamps/atlanta/tracks">Tracks</a>
                        </li>
                        <li>
                            <a href="/bootcamps/atlanta/location">Location</a>
                        </li>
                        <li>
                            <a href="/bootcamps/atlanta/faq">FAQ</a>
                        </li>
                        <li>
                            <a href="https://bcatlanta.cventevents.com/dQrnMm" target="_blank">REGISTER NOW</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AtlantaNavbar