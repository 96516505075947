import React, { useEffect } from 'react'
import AtlantaTemplate from '../../../templates/AtlantaTemplate'

const AtlantaTracks = () => {

    useEffect(() => {
        customElements.whenDefined("tds-modal").then(() => {
            document.querySelectorAll('.track-close').forEach(element => {
                element.onclick = () => { 
                    closeTrackModal(); 
                    return false; 
                }
            })
        })

        document.querySelectorAll('[data-overlay-button]').forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault()
                const id = e.target.href.split('#')[1]
                showOverlay(id)
            })
        })
    }, [])

    const closeTrackModal = () => {
        document.querySelectorAll('.track-modal').forEach(element => {element.open=false;})
    }

    const showOverlay = (id) => {
        //document.querySelector('.light-modal.in').classList.remove('in')
        document.querySelector(`#${id}`).classList.remove('hide')
        document.querySelector(`#${id}`).classList.add('in')  
        document.querySelector(`#${id}`).open = true;
        
        setTimeout(() => {
            document.querySelectorAll('.track-modal').forEach(oneModal => { 
                try {  
                    oneModal.shadowRoot.querySelector('.modal__close').onclick = () => {
                        closeTrackModal();
                        return false; 
                    }
                }catch(e){}
            });
        }, 500);
    }

    return (
        <AtlantaTemplate>
            <div className="salesforce-footer"></div>
            <div className="heroContent">
                <div className="heroSky">
                <div className="hottieCenter">
                    <img className="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
                </div>
                <img className="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_USA-Balloon.gif" alt="background" />
                </div>
            </div>
            <section className="container-content">
                <h1 className="h1Internal">TRACKS</h1>
                <h3>Choose your adventure.</h3>

                <div className="hilight">
                    <div className="hilight-inner">
                        <h4>
                            Immerse yourself in 6 days of expert-led<br />
                            learning and certification.
                        </h4>
                        <p>Choose from one of 12 role-based learning tracks spanning Administrator, Developer, Architect, Marketer, and Data Rockstar, and deep-dive into Salesforce, MuleSoft, and Tableau with Salesforce Certified Instructors.</p>
                    </div>
                </div>

                <div className="tracklist">
                <div className="track">
                    <h4>Administrator Tracks</h4>
                    <div className="trackBg">
                        <p className="audience" ><a data-overlay-button="" href="#track-modal-1">Administrator &gt;</a></p>                  
                    </div>

                    <div className="panel-group" id="adm-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-1">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#adm-accordion" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                    Administrator Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-1">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Administrator</p>

                                    <p>
                                        New to the world of Salesforce administration and ready to hit the ground running? If so, this track is for you. Learn the fundamentals of administering Salesforce, including how to set up new users and
                                        ensure they have access to the data they need. Discover how to import data from external systems, automate business processes, create reports and dashboards to analyze real-time data, and solve business
                                        problems by customizing the Salesforce Platform.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Apply the best practices to become a highly-skilled administrator.</li>
                                        <li>Customize your application, including page layouts, fields, tabs, and business processes.</li>
                                        <li>Create a secure Salesforce environment.</li>
                                        <li>Generate reports and dashboards.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>

                                    <ul>
                                        <li>New Salesforce Administrators</li>
                                        <li>Sales operations or power users looking to deepen their Salesforce knowledge</li>
                                        <li>Anyone looking to earn their Salesforce Administrator credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-2">Advanced Administrator &gt;</a></p>
                    </div>

                    <div className="panel-group" id="adm-accordion2" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-2">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#adm-accordion2" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    Advanced Administrator Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-2">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Advanced Administrator</p>

                                    <p>
                                        Are you an experienced Salesforce Administrator looking to up your game? If so, this track is for you. Learn how to extend Salesforce with custom objects, Lightning applications, and dynamic capabilities.
                                        Streamline processes and increase efficiency with a deep dive into the capabilities and structure of Salesforce Flow. Get hands-on experience meeting complex business needs by troubleshooting data and record
                                        access, implementing measures to improve data quality, and creating advanced reports and dashboards.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Design and implement solutions involving record visibility, custom objects, and Lightning applications.</li>
                                        <li>Utilize dynamic capabilities to streamline page layouts and elevate the end-user experience.</li>
                                        <li>Create complex flows to solve business needs, and troubleshoot to understand and solve flow-related errors.</li>
                                        <li>Generate advanced reports and dashboards to gain insights and inform business decisions.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>

                                    <ul>
                                        <li>Experienced Salesforce Administrators with at least 6 months of experience</li>
                                        <li>Salesforce Certified Administrators looking to earn their Advanced Administrator credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-afb">Admin Flow Builder&gt;</a></p>
                    </div>

                    <div className="panel-group" id="adm-accordionafb" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-afb">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#adm-accordionafb" href="#collapse-afb" aria-expanded="false" aria-controls="collapse-afb">
                                    Admin Flow Builder Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-afb" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-afb">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Admin Flow Builder</p>

                                    <p>
                                        Have experience as a Salesforce Administrator but need to learn how to leverage Flow as your no-code solution to automate complex business processes? If so, this track is for you. Learn how to think like a
                                        developer to design and build using Flow.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Apply basic developer language to apply Flow solutions.</li>
                                        <li>Analyze and translate business processes to Flow given use cases and scenarios.</li>
                                        <li>Design and develop solutions using Flow.</li>
                                        <li>Apply the best practices when building with Flow.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>

                                    <ul>
                                        <li>Flow implementers, specifically Salesforce Administrators who require training to build Flow solutions from beginning to end by using requirements and building and testing in Flow</li>
                                        <li>Salesforce Certified Administrators (strongly recommended)</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="track">
                    <h4>Developer Tracks</h4>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-3">Platform Developer &gt;</a></p>
                    </div>

                    <div className="panel-group" id="dev-accordion1" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-3">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#dev-accordion1" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    Platform Developer Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-3">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Platform Developer</p>

                                    <p>
                                        Are you an experienced programmer who already knows how to build pro-code solutions using object-oriented programming languages such as Java? Are you familiar with the Salesforce Security and Data Model and
                                        the no-code and low-code capabilities of the platform? If so, this track is for you. Learn how to create and design pro-code solutions using our language (Apex) that take advantage of no-code customizations.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Customize business logic using Apex classes and triggers.</li>
                                        <li>Access data in Salesforce using SOQL.</li>
                                        <li>Manipulate records in Salesforce using DML.</li>
                                        <li>Employ best practices for designing efficient Apex solutions.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Developers with knowledge of the Salesforce Platform and* *experience in object-oriented programming who want to transfer their programming skills to the Salesforce Platform</li>
                                        <li>Anyone looking to earn their Salesforce Platform Developer I credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-4">Lightning Web Components Developer &gt;</a></p>
                    </div>

                    <div className="panel-group" id="dev-accordion2" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-4">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#dev-accordion2" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    Lightning Web Components Developer Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-4">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Lightning Web Components Developer</p>

                                    <p>
                                        Lightning Web Components (LWC) is a modern JavaScript programming model for building web applications and interfaces, built on the best of web standards. In this track, learn how to create Lightning web
                                        components and assemble them into enterprise applications. Explore how to build components for use in Lightning Experience and the Salesforce mobile app, how to write less code and do more with your
                                        Salesforce data, and how to set up a development environment and the tools to build, test, and deploy web components.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Develop Lightning web components using the Salesforce Extensions for VS Code.</li>
                                        <li>Debug Lightning web components.</li>
                                        <li>Synchronize source code with a Salesforce environment using the Salesforce CLI.</li>
                                        <li>Set up communication between Lightning web components.</li>
                                        <li>Use JavaScript to handle user interactions.</li>
                                        <li>Develop Lightning web components for use in Lightning App Builder.</li>
                                        <li>Read and write Salesforce data from a Lightning web component using Lightning Data Services.</li>
                                        <li>Style Lightning web components with CSS and the Salesforce Lightning Design System.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Web developers with an interest in building single-page applications for Salesforce using LWC</li>
                                        <li>Salesforce Developers looking to develop custom experiences using LWC and Salesforce DX</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-5">MuleSoft Developer &gt;</a></p>
                    </div>

                    <div className="panel-group" id="dev-accordion2" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-4">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#dev-accordion2" href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    MuleSoft Developer Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-4">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">MuleSoft Developer</p>

                                    <p>
                                        Looking to earn your MuleSoft Certified Developer Level 1 credential? If so, this track is for you. Learn how to prepare for the exam by exploring the exam’s topic areas, mapping them to the Anypoint
                                        Platform Development: Fundamentals class, and identifying specific areas to study. Validate that you have the knowledge and skills to design, build, test, debug, deploy, and manage basic application
                                        programming interfaces (APIs) and integrations—moving from Anypoint Platform to Anypoint Studio and back. While aimed at developers, this track also covers everything you need to take the new MuleSoft
                                        Certified Integration Associate certification exam.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Understand the exam structure and question types.</li>
                                        <li>Interpret the concepts being tested by an exam question.</li>
                                        <li>Determine how questions relate to developing on the Anypoint Platform.</li>
                                        <li>Allocate your time studying for the exam.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Anyone with 6+ months experience developing on the Anypoint Platform</li>
                                        <li>Anyone who has attended the Anypoint Platform Development: Fundamentals self-paced or instructor-led class</li>
                                        <li>Anyone looking to earn their MuleSoft Certified Developer Level 1 credential</li>
                                        <li>Anyone looking to earn their MuleSoft Certified Integration Associate credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="track">
                    <h4>Architect Tracks</h4>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-6">Data Architect &gt;</a></p>
                    </div>

                    <div className="panel-group" id="arc-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-5">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#arc-accordion" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                    Data Architect Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-5">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Data Architect</p>

                                    <p>
                                        Are you ready to transition your thinking to an Architect mindset and start building your Data Architect expertise? If so, this track is for you. Dive into the data domain of the Salesforce Platform. Learn
                                        about data modeling, Salesforce data management, large data management, data migration, master data management, data governance, and the considerations you should make when architecting a high-performing
                                        enterprise data management solution on the Salesforce Platform.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Design scalable data models.</li>
                                        <li>Grasp Salesforce data management.</li>
                                        <li>Understand large data volume considerations.</li>
                                        <li>Make decisions about data migration, master data management, and data governance.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Aspiring Salesforce Architects</li>
                                        <li>Existing business analysts, consultants, or technical experts working to earn their Salesforce Data Architect credential</li>
                                        <li>Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-intA">Integration Architect &gt;</a></p>
                    </div>

                    <div className="panel-group" id="sys-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-intA">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#sys-accordion" href="#collapse-intA" aria-expanded="false" aria-controls="collapse-intA">
                                    Integration Architect Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-intA" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-intA">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Integration Architect</p>

                                    <p>
                                        Are you ready to transition your thinking to an Architect mindset and start building your Integration Architect expertise? If so, this track is for you. Dive into the integration domain of the Salesforce Platform. Learn about integration architecture, integration capabilities, integration patterns, integration security, and the considerations you should make when architecting a high-performing enterprise integration solution on the Salesforce Platform.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Decide between integration architectures</li>
                                        <li>Decide the right integration capabilities for a solution.</li>
                                        <li>Implement event-driven architecture.</li>
                                        <li>Apply integration security.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Aspiring Salesforce Architects </li>
                                        <li>Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform</li>
                                        <li>Existing business analysts, consultants, or technical experts working to earn their Salesforce Integration Architect credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-7">Sharing and Visibility Architect &gt;</a></p>
                    </div>

                    <div className="panel-group" id="sys-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-6">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#sys-accordion" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                    Sharing and Visibility Architect Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-6">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Sharing and Visibility Architect</p>

                                    <p>
                                        Are you ready to transition your thinking to an Architect mindset and start building your Sharing and Visibility Architect expertise? If so, this track is for you. Discover how to design secure architectures
                                        that ensure only approved users can gain access, restrict user access to what's essential to get work done and protect data within the system from being compromised. Learn about the considerations you should
                                        make when architecting a high-performing enterprise secure solution on the Salesforce Platform using a Well-Architected Framework.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Manage security with no-code and pro-code security features.</li>
                                        <li>Manage security for external users.</li>
                                        <li>Handle sensitive data using Encryption.</li>
                                        <li>Grasp the implications of the security model.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Aspiring Salesforce Architects</li>
                                        <li>Existing business analysts, consultants, or technical experts working to earn their Salesforce Sharing and Visibility Architect credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="track">
                    <h4>Tableau Track</h4>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-tdr">Tableau Desktop Rockstar  &gt;</a></p>
                    </div>

                    <div className="panel-group" id="em-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-tdr">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#em-accordion" href="#collapse-tdr" aria-expanded="false" aria-controls="collapse-tdr">
                                    Tableau Desktop Rockstar Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-tdr" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-tdr">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Tableau Desktop Rockstar</p>

                                    <p>
                                        Are you looking to fast-track your path to becoming a Tableau data rockstar? If so, this track is for you. Learn how to use Tableau to connect to data, and then sort, filter, group, and extract that data to
                                        glean business insights and improve business performance. Ramp up quickly on foundational and advanced Tableau skills (Desktop I & II) so you can earn your Tableau Desktop Specialist or Tableau Data Analyst
                                        credential.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Use the Tableau workspace to create a range of essential and advanced chart types for analysis.</li>
                                        <li>Combine data from multiple tables using joins, unions, and relationships.</li>
                                        <li>Create basic and more advanced calculations, including table calculations, to modify data as needed for analysis.</li>
                                        <li>Use statistical techniques to analyze data.</li>
                                        <li>Use parameters and input controls to support audience analysis.</li>
                                        <li>Build interactive dashboards to reveal data insights, using techniques for guided analytics, interactive dashboard design, and visual best practices.</li>
                                        <li>Describe how to share and publish visualizations.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Anyone looking to ramp up quickly on both foundational and more advanced Tableau skills</li>
                                        <li>Anyone looking to earn their Salesforce Tableau Desktop Specialist credential or learn a solid foundation for the Tableau Data Analyst credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="track">
                    <h4>Marketer Tracks</h4>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-8">Marketing Cloud Email Specialist  &gt;</a></p>
                    </div>

                    <div className="panel-group" id="em-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-7">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#em-accordion" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                                    Marketing Cloud Email Specialist Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-7">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Marketing Cloud Email Specialist</p>

                                    <p>
                                        Are you looking to get up and running on the Salesforce Marketing Cloud Email app? If so, this track is for you. Gain hands-on experience managing subscriber data, segmenting data to deliver relevant
                                        messages, creating emails with the best practices in mind, learning about the various automation tools available to automate campaigns and daily tasks, analyzing results, and understanding metrics.
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Understand the different ways to store data in Marketing Cloud and build relational data models.</li>
                                        <li>Create targeted, relevant messages utilizing simple and advanced segmentation tools based on subscriber data and behavior.</li>
                                        <li>Leverage different testing and sending options for targeted messages.</li>
                                        <li>Discover automated methods for sending different email communications utilizing triggers, Automation Studio, and Journey Builder.</li>
                                        <li>Create reports and analyze data for further insights.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Digital email marketers responsible for managing their organization’s Marketing Cloud orgs</li>
                                        <li>Anyone interested in earning their Salesforce Marketing Cloud Email Specialist credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="trackBg">
                        <p className="audience"><a data-overlay-button="" href="#track-modal-9">Marketing Cloud Account Engagement Specialist  &gt;</a></p>
                    </div>

                    <div className="panel-group" id="em-accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="trk-9">
                            <h4 className="panel-title">
                                <a className="collapsed" role="button" data-toggle="collapse" data-parent="#em-accordion" href="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                                    Marketing Cloud Account Engagement Specialist Bootcamp Track &gt;
                                </a>
                            </h4>
                            </div>
                            <div id="collapse-9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-9">
                            <div className="panel-content">
                                <div className="panel-row">
                                    <p className="modal-head">Marketing Cloud Account Engagement Specialist</p>

                                    <p>
                                        Looking to expand your Marketing Cloud Account Engagement (formerly known as Pardot) skills to design, build, and implement marketing workflows? Want to learn how to use Marketing Cloud Account Engagement-generated reports to make data-driven decisions? If so, this track is for you. Get hands-on with Marketing Cloud Account Engagement and learn how to build strategic marketing processes while incorporating tools such as lead scoring, email marketing, lead generation, and reporting.                               
                                    </p>

                                    <p className="modal-subhead">Learn how to:</p>
                                    <ul>
                                        <li>Enable the Account Engagement App.</li>
                                        <li>Explain the relationship created between Account Engagement and Salesforce.</li>
                                        <li>Generate leads with Account Engagement’s various lead generation tools.</li>
                                        <li>Manage leads with Account Engagement’s lead management tools.</li>
                                        <li>Engage leads with Account Engagement’s lead engagement tools.</li>
                                        <li>Qualify leads with Account Engagement’s scoring and grading functionality.</li>
                                        <li>Interpret data generated via Account Engagement’s reporting capabilities.</li>
                                        <li>Design and execute successful end-to-end marketing workflows.</li>
                                    </ul>

                                    <p className="modal-subhead">Audience:</p>
                                    <ul>
                                        <li>Marketers and/or end users with 2-6 months experience using Marketing Cloud Account Engagement to create marketing workflows</li>
                                        <li>Anyone interested in earning their Salesforce Marketing Cloud Account Engagement Specialist credential</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="container-content">
                <div className="center">
                <img className="logo logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/2023-Bootcamp-Seal_Atlanta_Color.png" />
                </div>
            </section>

            <div className="characters schedule-bg">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/tracks/Tracks.png" />
            </div>

            <tds-modal class="track-modal hide" id="track-modal-1" open="false">
                <div slot="heading">
                Administrator
                </div>
        
                <div slot="content">
                <p>
                    New to the world of Salesforce administration and ready to hit the ground running? If so, this track is for you. Learn the fundamentals of administering Salesforce, including how to set up new users and ensure they have access to the data they need. Discover how to import data from external systems, automate business processes, create reports and dashboards to analyze real-time data, and solve business problems by customizing the Salesforce Platform.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Apply the best practices to become a highly-skilled administrator.
                    </li>
                    <li>
                        Customize your application, including page layouts, fields, tabs, and business processes.
                    </li>
                    <li>
                        Create a secure Salesforce environment.
                    </li>
                    <li>
                        Generate reports and dashboards.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        New Salesforce Administrators
                    </li>
                    <li>
                        Sales operations or power users looking to deepen their Salesforce knowledge
                    </li>
                    <li>
                        Anyone looking to earn their Salesforce Administrator credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-2" open="false">
                <div slot="heading">
                Advanced Administrator
                </div>
        
                <div slot="content">
                <p>
                    Are you an experienced Salesforce Administrator looking to up your game? If so, this track is for you. Learn how to extend Salesforce with custom objects, Lightning applications, and dynamic capabilities. Streamline processes and increase efficiency with a deep dive into the capabilities and structure of Salesforce Flow. Get hands-on experience meeting complex business needs by troubleshooting data and record access, implementing measures to improve data quality, and creating advanced reports and dashboards.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Design and implement solutions involving record visibility, custom objects, and Lightning applications.
                    </li>
                    <li>
                        Utilize dynamic capabilities to streamline page layouts and elevate the end-user experience.
                    </li>
                    <li>
                        Create complex flows to solve business needs, and troubleshoot to understand and solve flow-related errors.
                    </li>
                    <li>
                        Generate advanced reports and dashboards to gain insights and inform business decisions.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Experienced Salesforce Administrators with at least 6 months of experience
                    </li>
                    <li>
                        Salesforce Certified Administrators looking to earn their Advanced Administrator credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-afb" open="false">
                <div slot="heading">
                Admin Flow Builder
                </div>
        
                <div slot="content">
                <p>
                    Have experience as a Salesforce Administrator but need to learn how to leverage Flow as your no-code solution to automate complex business processes? If so, this track is for you. Learn how to think like a developer to design and build using Flow.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Apply basic developer language to apply Flow solutions.
                    </li>
                    <li>
                        Analyze and translate business processes to Flow given use cases and scenarios.
                    </li>
                    <li>
                        Design and develop solutions using Flow.
                    </li>
                    <li>
                        Apply the best practices when building with Flow.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Flow implementers, specifically Salesforce Administrators who require training to build Flow solutions from beginning to end by using requirements and building and testing in Flow
                    </li>
                    <li>
                        Salesforce Certified Administrators (strongly recommended)
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-3" open="false">
                <div slot="heading">
                Platform Developer
                </div>
        
                <div slot="content">
                <p>
                    Are you an experienced programmer who already knows how to build pro-code solutions using object-oriented programming languages such as Java? Are you familiar with the Salesforce Security and Data Model and the no-code and low-code capabilities of the platform? If so, this track is for you. Learn how to create and design pro-code solutions using our language (Apex) that take advantage of no-code customizations.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Customize business logic using Apex classes and triggers.
                    </li>
                    <li>
                        Access data in Salesforce using SOQL.
                    </li>
                    <li>
                        Manipulate records in Salesforce using DML.
                    </li>
                    <li>
                        Employ best practices for designing efficient Apex solutions.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Developers with knowledge of the Salesforce Platform and* *experience in object-oriented programming who want to transfer their programming skills to the Salesforce Platform
                    </li>
                    <li>
                        Anyone looking to earn their Salesforce Platform Developer I credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-4" open="false">
                <div slot="heading">
                Lightning Web Components Developer
                </div>
        
                <div slot="content">
                <p>
                    Lightning Web Components (LWC) is a modern JavaScript programming model for building web applications and interfaces, built on the best of web standards. In this track, learn how to create Lightning web components and assemble them into enterprise applications. Explore how to build components for use in Lightning Experience and the Salesforce mobile app, how to write less code and do more with your Salesforce data, and how to set up a development environment and the tools to build, test, and deploy web components.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Develop Lightning web components using the Salesforce Extensions for VS Code.
                    </li>
                    <li>
                        Debug Lightning web components.
                    </li>
                    <li>
                        Synchronize source code with a Salesforce environment using the Salesforce CLI.
                    </li>
                    <li>
                        Set up communication between Lightning web components.
                    </li>
                    <li>
                        Use JavaScript to handle user interactions.
                    </li>
                    <li>
                        Develop Lightning web components for use in Lightning App Builder.
                    </li>
                    <li>
                        Read and write Salesforce data from a Lightning web component using Lightning Data Services.
                    </li>
                    <li>
                        Style Lightning web components with CSS and the Salesforce Lightning Design System.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Web developers with an interest in building single-page applications for Salesforce using LWC
                    </li>
                    <li>
                        Salesforce Developers looking to develop custom experiences using LWC and Salesforce DX
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-5" open="false">
                <div slot="heading">
                MuleSoft Developer
                </div>
        
                <div slot="content">
                <p>
                    Looking to earn your MuleSoft Certified Developer Level 1 credential? If so, this track is for you. Learn how to prepare for the exam by exploring the exam&rsquo;s topic areas, mapping them to the Anypoint Platform Development: Fundamentals class, and identifying specific areas to study. Validate that you have the knowledge and skills to design, build, test, debug, deploy, and manage basic application programming interfaces (APIs) and integrations&mdash;moving from Anypoint Platform to Anypoint Studio and back. While aimed at developers, this track also covers everything you need to take the new MuleSoft Certified Integration Associate certification exam.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Understand the exam structure and question types.
                    </li>
                    <li>
                        Interpret the concepts being tested by an exam question.
                    </li>
                    <li>
                        Determine how questions relate to developing on the Anypoint Platform.
                    </li>
                    <li>
                        Allocate your time studying for the exam.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Anyone with 6+ months experience developing on the Anypoint Platform
                    </li>
                    <li>
                        Anyone who has attended the Anypoint Platform Development: Fundamentals self-paced or instructor-led class
                    </li>
                    <li>
                        Anyone looking to earn their MuleSoft Certified Developer Level 1 credential
                    </li>
                    <li>
                        Anyone looking to earn their MuleSoft Certified Integration Associate credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-6" open="false">
                <div slot="heading">
                Data Architect
                </div>
        
                <div slot="content">
                <p>
                    Are you ready to transition your thinking to an Architect mindset and start building your Data Architect expertise? If so, this track is for you. Dive into the data domain of the Salesforce Platform. Learn about data modeling, Salesforce data management, large data management, data migration, master data management, data governance, and the considerations you should make when architecting a high-performing enterprise data management solution on the Salesforce Platform.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Design scalable data models.
                    </li>
                    <li>
                        Grasp Salesforce data management.
                    </li>
                    <li>
                        Understand large data volume considerations.
                    </li>
                    <li>
                        Make decisions about data migration, master data management, and data governance.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Aspiring Salesforce Architects
                    </li>
                    <li>
                        Existing business analysts, consultants, or technical experts working to earn their Salesforce Data Architect credential
                    </li>
                    <li>
                        Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-intA" open="false">
                <div slot="heading">
                Integration Architect
                </div>
        
                <div slot="content">
                <p>
                    Are you ready to transition your thinking to an Architect mindset and start building your Integration Architect expertise? If so, this track is for you. Dive into the integration domain of the Salesforce Platform. Learn about integration architecture, integration capabilities, integration patterns, integration security, and the considerations you should make when architecting a high-performing enterprise integration solution on the Salesforce Platform.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Decide between integration architectures
                    </li>
                    <li>
                        Decide the right integration capabilities for a solution.
                    </li>
                    <li>
                        Implement event-driven architecture.
                    </li>
                    <li>
                        Apply integration security.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Aspiring Salesforce Architects
                    </li>
                    <li>
                        Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform
                    </li>
                    <li>
                        Existing business analysts, consultants, or technical experts working to earn their Salesforce Integration Architect credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-7" open="false">
                <div slot="heading">
                Sharing and Visibility Architect
                </div>
        
                <div slot="content">
                <p>
                    Are you ready to transition your thinking to an Architect mindset and start building your Sharing and Visibility Architect expertise? If so, this track is for you. Discover how to design secure architectures that ensure only approved users can gain access, restrict user access to what&#39;s essential to get work done and protect data within the system from being compromised. Learn about the considerations you should make when architecting a high-performing enterprise secure solution on the Salesforce Platform using a Well-Architected Framework.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Manage security with no-code and pro-code security features.
                    </li>
                    <li>
                        Manage security for external users.
                    </li>
                    <li>
                        Handle sensitive data using Encryption.
                    </li>
                    <li>
                        Grasp the implications of the security model.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Aspiring Salesforce Architects
                    </li>
                    <li>
                        Existing business analysts, consultants, or technical experts working to earn their Salesforce Sharing and Visibility Architect credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-tdr" open="false">
                <div slot="heading">
                Tableau Desktop Rockstar
                </div>
        
                <div slot="content">
                <p>
                    Are you looking to fast-track your path to becoming a Tableau data rockstar? If so, this track is for you. Learn how to use Tableau to connect to data, and then sort, filter, group, and extract that data to glean business insights and improve business performance. Ramp up quickly on foundational and advanced Tableau skills (Desktop I &amp; II) so you can earn your Tableau Desktop Specialist or Tableau Data Analyst credential.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Use the Tableau workspace to create a range of essential and advanced chart types for analysis.
                    </li>
                    <li>
                        Combine data from multiple tables using joins, unions, and relationships.
                    </li>
                    <li>
                        Create basic and more advanced calculations, including table calculations, to modify data as needed for analysis.
                    </li>
                    <li>
                        Use statistical techniques to analyze data.
                    </li>
                    <li>
                        Use parameters and input controls to support audience analysis.
                    </li>
                    <li>
                        Build interactive dashboards to reveal data insights, using techniques for guided analytics, interactive dashboard design, and visual best practices.
                    </li>
                    <li>
                        Describe how to share and publish visualizations.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Anyone looking to ramp up quickly on both foundational and more advanced Tableau skills
                    </li>
                    <li>
                        Anyone looking to earn their Salesforce Tableau Desktop Specialist credential or learn a solid foundation for the Tableau Data Analyst credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-8" open="false">
                <div slot="heading">
                Marketer
                </div>
        
                <div slot="content">
                <p>
                    Are you looking to get up and running on the Salesforce Marketing Cloud Email app? If so, this track is for you. Gain hands-on experience managing subscriber data, segmenting data to deliver relevant messages, creating emails with the best practices in mind, learning about the various automation tools available to automate campaigns and daily tasks, analyzing results, and understanding metrics.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Understand the different ways to store data in Marketing Cloud and build relational data models.
                    </li>
                    <li>
                        Create targeted, relevant messages utilizing simple and advanced segmentation tools based on subscriber data and behavior.
                    </li>
                    <li>
                        Leverage different testing and sending options for targeted messages.
                    </li>
                    <li>
                        Discover automated methods for sending different email communications utilizing triggers, Automation Studio, and Journey Builder.
                    </li>
                    <li>
                        Create reports and analyze data for further insights.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Digital email marketers responsible for managing their organization&rsquo;s Marketing Cloud orgs
                    </li>
                    <li>
                        Anyone interested in earning their Salesforce Marketing Cloud Email Specialist credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>

            <tds-modal class="track-modal hide" id="track-modal-9" open="false">
                <div slot="heading">
                Marketing Cloud Account Engagement Specialist
                </div>
        
                <div slot="content">
                <p>
                    Looking to expand your Marketing Cloud Account Engagement (formerly known as Pardot) skills to design, build, and implement marketing workflows? Want to learn how to use Marketing Cloud Account Engagement-generated reports to make data-driven decisions? If so, this track is for you. Get hands-on with Marketing Cloud Account Engagement and learn how to build strategic marketing processes while incorporating tools such as lead scoring, email marketing, lead generation, and reporting.
                </p>
        
                <p className="modal-subhead">
                    Learn how to:
                </p>
        
                <ul>
                    <li>
                        Enable the Account Engagement App.
                    </li>
                    <li>
                        Explain the relationship created between Account Engagement and Salesforce.
                    </li>
                    <li>
                        Generate leads with Account Engagement&rsquo;s various lead generation tools.
                    </li>
                    <li>
                        Manage leads with Account Engagement&rsquo;s lead management tools.
                    </li>
                    <li>
                        Engage leads with Account Engagement&rsquo;s lead engagement tools.
                    </li>
                    <li>
                        Qualify leads with Account Engagement&rsquo;s scoring and grading functionality.
                    </li>
                    <li>
                        Interpret data generated via Account Engagement&rsquo;s reporting capabilities.
                    </li>
                    <li>
                        Design and execute successful end-to-end marketing workflows.
                    </li>
                </ul>
        
                <p className="modal-subhead">
                    Audience:
                </p>
        
                <ul>
                    <li>
                        Marketers and/or end users with 2-6 months experience using Marketing Cloud Account Engagement to create marketing workflows
                    </li>
                    <li>
                        Anyone interested in earning their Salesforce Marketing Cloud Account Engagement Specialist credential
                    </li>
                </ul>
                </div>
        
                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
        </AtlantaTemplate>
    )
}

export default AtlantaTracks
